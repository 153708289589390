import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";

import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import { putServices } from "helpers/put";
import { GetServiceUpdate } from "interface/service";
import { Erorr } from "../../../../types/auth";
import upload from "helpers/uplouds";
import { useAuth } from "context/AuthContext";
export const ServiceUpdate = () => {
    
    const { id } = useParams<{ id: string }>();
    const [loader, setLoader] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [photo, setPhoto] = useState<any>(null);
    const [eror, setEror] = useState<Erorr>();
    const {user} = useAuth();
    useEffect(() => {
        const fetchData = async () => {
          const data = await getServices<GetServiceUpdate>(`/Service/GetByIdService/${id}`,user);
console.log(data);

     setPhoto(data?.photoPath)
       
          formik.setValues({
            image:null,
            az: {
                title: data?.language.find(x=>x.langCode==="az")?.title||"",
                description:data?.language.find(x=>x.langCode==="az")?.description||"" ,
                metaKey: data?.language.find(x=>x.langCode==="az")?.metaKey||"",
                metaDescription:data?.language.find(x=>x.langCode==="az")?.metaDescription||"",
                answer:data?.language.find(x=>x.langCode==="az")?.answer||"",
                question:data?.language.find(x=>x.langCode==="az")?.question||""

            },
            ru: {
                title: data?.language.find(x=>x.langCode==="ru-RU")?.title||"",
                description:data?.language.find(x=>x.langCode==="ru-RU")?.description||"" ,
                metaKey: data?.language.find(x=>x.langCode==="ru-RU")?.metaKey||"",
                metaDescription:data?.language.find(x=>x.langCode==="ru-RU")?.metaDescription||"",
                answer:data?.language.find(x=>x.langCode==="ru-RU")?.answer||"",
                question:data?.language.find(x=>x.langCode==="ru-RU")?.question||""
            },
            en: {
                title: data?.language.find(x=>x.langCode==="en-US")?.title||"",
                description:data?.language.find(x=>x.langCode==="en-US")?.description||"" ,
                metaKey: data?.language.find(x=>x.langCode==="en-US")?.metaKey||"",
                metaDescription:data?.language.find(x=>x.langCode==="en-US")?.metaDescription||"",
                answer:data?.language.find(x=>x.langCode==="en-US")?.answer||"",
                question:data?.language.find(x=>x.langCode==="en-US")?.question||""
            }
        });
        };
   
    
        fetchData();
      }, [t,id]);

    const formik = useFormik({
        initialValues: {
            image:null,
            az: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                question:"",
                answer:"",
            },
            ru: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                question:"",
                answer:"",
            },
            en: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                question:"",
                answer:"",
            }
        },
        validationSchema: Yup.object({
            az: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                answer: Yup.string().required("Zəhmət olmasa cavabi daxil edin"),
                question: Yup.string().required("Zəhmət olmasa suali daxil edin"),
            }),
            ru: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                answer: Yup.string().required("Zəhmət olmasa cavabi daxil edin"),
                question: Yup.string().required("Zəhmət olmasa suali daxil edin"),
              }),
            en: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                answer: Yup.string().required("Zəhmət olmasa cavabi daxil edin"),
                question: Yup.string().required("Zəhmət olmasa suali daxil edin"),
             })
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);
if (values.image!==null) {
 
        const imageUrl = await upload(values.image,user,navigate);
        setEror(imageUrl)
        const requestBody = {
            photoPath: imageUrl.message,
            language: [
              {
                title: values.az.title,
                description: values.az.description,
                langCode: "az",
                metaKey: values.az.metaKey,
                metaDescription: values.az.metaDescription,
                answer:values.az.answer ,
                question:values.az.question ,
              },
              {
                title: values.ru.title,
                description: values.ru.description,
                langCode: "ru-RU",
                metaKey: values.ru.metaKey,
                metaDescription: values.ru.metaDescription,
                answer:values.ru.answer ,
                question:values.ru.question ,
              }, 
              {
                title: values.en.title,
                description: values.en.description,
                langCode: "en-US",
                metaKey: values.en.metaKey,
                metaDescription: values.en.metaDescription,
                answer:values.en.answer ,
                question:values.en.question ,
              }
            ],
        }
      
       const response= await putServices(`/Service/Edit/${id}`,requestBody,user,navigate)
       setLoader(false);
       if (response.statusCode===201) {
        navigate("/service")
      resetForm()
      const Erorr = {
        statusCode: 0,
        message: '',
      };
    setEror(Erorr)
    }
}else{
            const requestBody = {
                photoPath: photo,
                language: [
                  {
                    title: values.az.title,
                    description: values.az.description,
                    langCode: "az",
                    metaKey: values.az.metaKey,
                    metaDescription: values.az.metaDescription,
                    answer:values.az.answer ,
                    question:values.az.question ,
                  },
                  {
                    title: values.ru.title,
                    description: values.ru.description,
                    langCode: "ru-RU",
                    metaKey: values.ru.metaKey,
                    metaDescription: values.ru.metaDescription,
                    answer:values.ru.answer ,
                    question:values.ru.question ,
                  }, 
                  {
                    title: values.en.title,
                    description: values.en.description,
                    langCode: "en-US",
                    metaKey: values.en.metaKey,
                    metaDescription: values.en.metaDescription,
                    answer:values.ru.answer ,
                    question:values.ru.question ,
                  }
                ],
            }
          
           const response= await putServices(`/Service/Edit/${id}`,requestBody,user,navigate)
           setLoader(false);
       
          if (response.statusCode===201) {
              navigate("/service")
            resetForm()
          }
        
        
    }}})
  




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Yeniləyin" pageTitle="Xidmət" />
            <Row>
            {eror?.statusCode===400&&<Alert variant="danger">{eror.message}</Alert>}
                <Col lg={12}>
                    <Card >
                        <Card.Body>
                            <Row >
                            <Col xxl={4}>
                            <label htmlFor="image" className="form-label">Xidmət şəkli</label>
        <input
          id="image"
          name="image"
          type="file"
          className={`form-control ${formik.touched.image && formik.errors.image ? 'border border-danger' : ''}`}
          onChange={(event) => {
            formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : null);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-danger">{formik.errors.image}</div>
        ) : null}
          {formik.values.image ? (
           <div className="mt-3 d-flex justify-content-center">
           <div style={{width:"100%",height: '200px', overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
             <img  src={URL.createObjectURL(formik.values.image)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
           </div>
         </div>
      ):(
        <div className="mt-3 d-flex justify-content-center">
        <div style={{width:"100%", height: '200px', overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
          <img  src={`${photo}`} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
        </div>
      </div>
      )}
        </Col>
        <Col xxl={8}>
                                    <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                    <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
      <Nav variant="tabs" className="nav nav-tabs" role="tablist">
        <Nav.Item>
          <Nav.Link className={`${formik.errors.az!==undefined&&"text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.ru!==undefined&&"text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.en!==undefined&&"text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
        </Nav.Item>
      </Nav>
     
      <Tab.Content className="tab-content" id="nav-tabContent">
        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.title"
                                                name="az.title"
                                                placeholder="Xidmətin adını daxil edin"
                                                value={formik.values.az.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.title} />
                                            {formik.errors.az?.title && formik.touched.az?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.description"
                                                name="az.description"
                                                placeholder="Xidmətin təsvirini daxil edin"
                                                value={formik.values.az.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.description} />
                                            {formik.errors.az?.description && formik.touched.az?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.question"
                                                name="az.question"
                                                placeholder="Sualı daxil edin"
                                                value={formik.values.az.question}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.question} />
                                            {formik.errors.az?.question && formik.touched.az?.question ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.question}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.answer"
                                                name="az.answer"
                                                placeholder="Cavabı daxil edin"
                                                value={formik.values.az.answer}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.answer} />
                                            {formik.errors.az?.answer && formik.touched.az?.answer ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.answer} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.metaDescription"
                                                name="az.metaDescription"
                                                placeholder="Xidmətin meta təsvirini daxil edin"
                                                value={formik.values.az.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.metaDescription} />
                                            {formik.errors.az?.metaDescription && formik.touched.az?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                    
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.metaKey"
                                                name="az.metaKey"
                                                placeholder="Xidmətin metaKeyini daxil edin"
                                                value={formik.values.az.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.metaKey} />
                                            {formik.errors.az?.metaKey && formik.touched.az?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                              
                     
        </Tab.Pane>
     
        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.title"
                                                name="ru.title"
                                                placeholder="Xidmətin başlığını daxil edin"
                                                value={formik.values.ru.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.title} />
                                            {formik.errors.ru?.title && formik.touched.ru?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.description"
                                                name="ru.description"
                                                placeholder="Xidmətin təsvirini daxil edin"
                                                value={formik.values.ru.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.description} />
                                            {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.question"
                                                name="ru.question"
                                                placeholder="Sualı daxil edin"
                                                value={formik.values.ru.question}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.question} />
                                            {formik.errors.ru?.question && formik.touched.az?.question ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.question}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.answer"
                                                name="ru.answer"
                                                placeholder="Cavabı daxil edin"
                                                value={formik.values.ru.answer}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.answer} />
                                            {formik.errors.ru?.answer && formik.touched.ru?.answer ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.answer} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.metaDescription"
                                                name="ru.metaDescription"
                                                placeholder="Xidmətin meta təsvirini daxil edin"
                                                value={formik.values.ru.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.metaDescription} />
                                            {formik.errors.ru?.metaDescription && formik.touched.ru?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                    
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.metaKey"
                                                name="ru.metaKey"
                                                placeholder="Xidmətin metaKeyini daxil edin"
                                                value={formik.values.ru.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.metaKey} />
                                            {formik.errors.ru?.metaKey && formik.touched.ru?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
        </Tab.Pane>
        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.title"
                                                name="en.title"
                                                placeholder="Xidmətin başlığını daxil edin"
                                                value={formik.values.en.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.title} />
                                            {formik.errors.en?.title && formik.touched.en?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.description"
                                                name="en.description"
                                                placeholder="Xidmətin təsvirini daxil edin"
                                                value={formik.values.en.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.description} />
                                            {formik.errors.en?.description && formik.touched.en?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.question"
                                                name="en.question"
                                                placeholder="Sualı daxil edin"
                                                value={formik.values.en.question}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.question} />
                                            {formik.errors.en?.question && formik.touched.en?.question ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.question}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.answer"
                                                name="en.answer"
                                                placeholder="Cavabı daxil edin"
                                                value={formik.values.en.answer}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.answer} />
                                            {formik.errors.en?.answer && formik.touched.en?.answer ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.answer} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.metaDescription"
                                                name="en.metaDescription"
                                                placeholder="Xidmətin meta təsvirini daxil edin"
                                                value={formik.values.en.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.metaDescription} />
                                            {formik.errors.en?.metaDescription && formik.touched.en?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                      
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.metaKey"
                                                name="en.metaKey"
                                                placeholder="Xidmətin metaKeyini daxil edin"
                                                value={formik.values.en.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.metaKey} />
                                            {formik.errors.en?.metaKey && formik.touched.en?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>  
            <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                                Yadda saxla
                                            </span>
                                        </Button>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>

            </div>
        </React.Fragment >
    );
}

