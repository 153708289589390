import { Navigate } from "react-router-dom";

import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
import OfflinePage from "pages/AuthenticationInner/Errors/Offline";

import Ecommerce from "pages/Dashboard";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import Article from "pages/Dashboard/Article";
import { ArticleCreate } from "pages/Dashboard/Article/create";
import { ArticleUpdate } from "pages/Dashboard/Article/update";
import { CategoryCreate } from "pages/Dashboard/Category/create";
import { CategoryUpdate } from "pages/Dashboard/Category/update";
import Category from "pages/Dashboard/Category";
import { FeedbackUpdate } from "pages/Dashboard/Feedback/update";
import { FeedbackCreate } from "pages/Dashboard/Feedback/create";
import Feedback from "pages/Dashboard/Feedback";
import { ProjectCreate } from "pages/Dashboard/Project/create";
import Project from "pages/Dashboard/Project";
import { ProjectUpdate } from "pages/Dashboard/Project/update";
import Service from "pages/Dashboard/Service";
import { ServiceCreate } from "pages/Dashboard/Service/create";
import { ServiceUpdate } from "pages/Dashboard/Service/update";
import About from "pages/Dashboard/About";
import { AboutCreate } from "pages/Dashboard/About/create";
import { AboutUpdate } from "pages/Dashboard/About/update";
import Partner from "pages/Dashboard/Partner";
import PhotoGallery from "pages/Dashboard/PhotoGallery";
import PartnerCreate from "pages/Dashboard/Partner/create";
import PhotoGalleryCreate from "pages/Dashboard/PhotoGallery/create";
import Users from "pages/Dashboard/User";

interface RouteItem {
    path: string;
    component: JSX.Element;
    roles?: string[];
    name?:string,
    exact?:boolean
  }
  
  const authProtectedRoutes: RouteItem[] = [
    // Dashboard
    { path: "/", component: <Ecommerce /> },
    { path: "/article", component: <Article />, roles: ['admin']  },
    { path: "/article/create", component: <ArticleCreate /> },
    { path: "/article/update/:id", component: <ArticleUpdate /> },
    { path: "/category", component: <Category /> },
    { path: "/category/create", component: <CategoryCreate /> },
    { path: "/category/update/:id", component: <CategoryUpdate /> },
    { path: "/feedback", component: <Feedback /> },
    { path: "/feedback/create", component: <FeedbackCreate /> },
    { path: "/feedback/update/:id", component: <FeedbackUpdate /> },
    { path: "/project", component: <Project /> },
    { path: "/project/create", component: <ProjectCreate /> },
    { path: "/project/update/:id", component: <ProjectUpdate/> },
    { path: "/service", component: <Service /> },
    { path: "/service/create", component: <ServiceCreate /> },
    { path: "/service/update/:id", component: <ServiceUpdate/> },
    { path: "/about", component: <About /> },
    { path: "/about/create", component: <AboutCreate /> },
    { path: "/about/update/:id", component: <AboutUpdate/> },
    { path: "/partner", component: <Partner /> },
    { path: "/partner/create", component: <PartnerCreate /> },
    { path: "/photoGallery", component: <PhotoGallery /> },
    { path: "/photoGallery/create", component: <PhotoGalleryCreate /> },
    { path: "/user", component: <Users /> },

    
    
    { path: "/", exact: true ,component: <Navigate to="/" /> },
    { path: "*", component: <Navigate to="/auth-404" /> },
    
    { path: "/", name : "Ecommerce Dashboard" ,component: <Ecommerce /> },
    { path: "/", exact: true, name : "Navigate" ,component: <Navigate to="/" /> },

];

const publicRoutes:RouteItem[] = [
    // Authentication
    { path: "/login", name : "Login" ,component: <Login /> },
    { path: "/logout", name : "Logout" ,component: <Logout /> },
    { path: "/register", name : "Register" ,component: <Register /> },

    // Authentication Inner
    // Error
    // Error 404
    { path: "/auth-404", name : "Fourzerofour" ,component: <Fourzerofour /> },

    // Error 500
    { path: "/auth-500", name : "Fivezerozero" ,component: <Fivezerozero /> },

    // Error 503
    { path: "/auth-503", name : "Fivezerothree" ,component: <Fivezerothree /> },

    // Offline Page
    { path: "/auth-offline", name : "OfflinePage" ,component: <OfflinePage /> },
];

export { authProtectedRoutes, publicRoutes };