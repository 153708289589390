import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import withRouter from "Common/withRouter";
import { useAuth } from "context/AuthContext";


const Logout = () => {
  const token = localStorage.getItem('refres');
  document.title = "Log Out | Texno Glabal";
  const {logout} = useAuth();
  useEffect(() => {
    logout()
}, [logout]);
  if (!token) {
    return <Navigate to={process.env.PUBLIC_URL + "/login"} />;
  }

  return <React.Fragment></React.Fragment>;
};

export default withRouter(Logout);