import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { getServices } from 'helpers/get';
import { useTranslation } from 'react-i18next';
import { deleteServices } from 'helpers/delete';
import Swal from 'sweetalert2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

export default function StandardImageList() {
  const { t } = useTranslation();
  const {user} = useAuth();
  const [data, setData] = React.useState<any>([]);
  const [isDeleite, setIsDeleite] = React.useState<boolean>(false);
  const IsDeleite =  () => {
    setIsDeleite(!isDeleite)
  }
  const navigate = useNavigate();
  const Deleite = async (id: string)  => {
    const result = await Swal.fire({
      title: 'Əminsiniz',
      text: "Bunu geri qaytara bilməyəcəksiniz!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Bəli, silin!',
      cancelButtonText: 'Bağla'
    });
  
    if (result.isConfirmed) {
    const response = await  deleteServices(`/PhotoGallery/DeletePhotoGallery/${id}`,user,navigate)
   
    if (response.statusCode===200) {
      IsDeleite();
      Swal.fire({
        title: 'Silindi!',
        text:`Şəkil uğurla silindi`,
        icon: 'success'
      });
    }
    }}
  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getServices<any[]>("/PhotoGallery/GetAllPhotoGallery",user, t('leng'));
      setData(data);

      
    };

    fetchData();
  }, [t,isDeleite]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  let cols = 3; // Varsayılan değer
  if (isMobile) {
    cols = 1;
  } else if (isTablet) {
    cols = 2;
  }
  return (
    <ThemeProvider theme={theme}>
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <ImageList sx={{ width: '100%',overflow: 'hidden' }} cols={cols} rowHeight={300}>
      {data&&data.map((item:any) => (
        <ImageListItem  key={item.id}>
          <img onClick={()=>Deleite(item.id)}
            srcSet={`${item.fileName}`}
            src={`${item.fileName}`}
            alt={"default"}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>

    </div>
    </ThemeProvider>
  );
}

