import BreadCrumb from 'Common/BreadCrumb'
import React from 'react'
import { Button, Card, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PartnerList from './PhotoList'

export default function Partner() {
  return (
    <React.Fragment>
    <div className="page-content">
        <Container fluid>
            <BreadCrumb title="Tərəfdaş" pageTitle="Admin" />
            <Card>
            <Card.Header>
            <Link to={"/partner/create"}> <Button className="btn-primary   bg-gradient">Yarat</Button></Link>
            </Card.Header>
            <Card.Body>
            <PartnerList/>
            </Card.Body>
          </Card>
           
                
                </Container>
            </div>
            </React.Fragment>
  )
}
