import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";

import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import { putServices } from "helpers/put";
import { GetProjectUpdate } from "interface/project";
import { FileWithPreview } from "./create";
import multipluplouds from "helpers/multipluplouds";
import { Erorr } from "../../../../types/auth";
import { useAuth } from "context/AuthContext";
import upload from "helpers/uplouds";
export const ProjectUpdate = () => {
    const { id } = useParams<{ id: string }>();
    const [loader, setLoader] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState<any>([]);
    const [photo, setphoto] = useState<any>("");

    const [eror, setEror] = useState<Erorr>();
    const { user } = useAuth();
    useEffect(() => {
        const fetchData = async () => {
            const data = await getServices<GetProjectUpdate>(`/Project/GetByIdProject/${id}`, user);


            setFiles(data?.photoPaths)


setphoto(data?.photoPath);


            formik.setValues({
                date: new Date(data?.projectYear || "").toISOString().split('T')[0],
                photos: [],
                photo: null,
                az: {
                    title: data?.language.find(x => x.langCode === "az")?.title || "",
                    description: data?.language.find(x => x.langCode === "az")?.description || "",
                    metaKey: data?.language.find(x => x.langCode === "az")?.metaKey || "",
                    metaDescription: data?.language.find(x => x.langCode === "az")?.metaDescription || "",
                    location: data?.language.find(x => x.langCode === "az")?.location || "",
                    service: data?.language.find(x => x.langCode === "az")?.service || "",
                },
                ru: {
                    title: data?.language.find(x => x.langCode === "ru-RU")?.title || "",
                    description: data?.language.find(x => x.langCode === "ru-RU")?.description || "",
                    metaKey: data?.language.find(x => x.langCode === "ru-RU")?.metaKey || "",
                    metaDescription: data?.language.find(x => x.langCode === "ru-RU")?.metaDescription || "",
                    location: data?.language.find(x => x.langCode === "ru-RU")?.location || "",
                    service: data?.language.find(x => x.langCode === "ru-RU")?.service || "",
                },
                en: {
                    title: data?.language.find(x => x.langCode === "en-US")?.title || "",
                    description: data?.language.find(x => x.langCode === "en-US")?.description || "",
                    metaKey: data?.language.find(x => x.langCode === "en-US")?.metaKey || "",
                    metaDescription: data?.language.find(x => x.langCode === "en-US")?.metaDescription || "",
                    location: data?.language.find(x => x.langCode === "en-US")?.location || "",
                    service: data?.language.find(x => x.langCode === "en-US")?.location || "",
                }
            });
        };


        fetchData();
    }, [t, id]);

    const formik = useFormik({
        initialValues: {
            date: "",
            photos: [],
            photo: null,
            az: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                location: "",
                service: "",

            },
            ru: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                location: "",
                service: "",
            },
            en: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                location: "",
                service: "",
            }
        },
        validationSchema: Yup.object({
            date: Yup.date()
                .required('Tarixi daxil edin'),
            az: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                location: Yup.string().required("Zəhmət olmasa yerini daxil edin"),
                service: Yup.string().required("Zəhmət olmasa xidməti daxil edin")
            }),
            ru: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                location: Yup.string().required("Zəhmət olmasa yerini daxil edin"),
                service: Yup.string().required("Zəhmət olmasa xidməti daxil edin")
            }),
            en: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                location: Yup.string().required("Zəhmət olmasa yerini daxil edin"),
                service: Yup.string().required("Zəhmət olmasa xidməti daxil edin")
            })
        }),
        onSubmit: async (values, { resetForm }) => {
         
            
            setLoader(true);

            if (values.photos.length > 0) {
                const imageUrl = await multipluplouds(values.photos, user, navigate);
console.log(imageUrl);

                setEror(imageUrl)

                if (imageUrl.statusCode === 200) {
                    if (values.photo) {
                        const imageUr = await upload(values.photo, user, navigate);
                        setEror(imageUr)
                        if (imageUr.statusCode === 200) {
                            const requestBody = {

                                projectYear: new Date(values.date).toISOString(),
                                photoPath: imageUr.message,
                                language: [
                                    {

                                        title: values.az.title,
                                        description: values.az.description,
                                        langCode: "az",
                                        metaKey: values.az.metaKey,
                                        metaDescription: values.az.metaDescription,
                                        location: values.az.location,
                                        service: values.az.service,
                                    },
                                    {
                                        title: values.ru.title,
                                        description: values.ru.description,
                                        langCode: "ru-RU",
                                        metaKey: values.ru.metaKey,
                                        metaDescription: values.ru.metaDescription,
                                        location: values.ru.service,
                                        service: values.ru.service,
                                    },
                                    {
                                        title: values.en.title,
                                        description: values.en.description,
                                        langCode: "en-US",
                                        metaKey: values.en.metaKey,
                                        metaDescription: values.en.metaDescription,
                                        location: values.en.location,
                                        service: values.en.service,
                                    }
                                ],
                                photoPaths: imageUrl.message,
                            }
                            const response = await putServices(`/Project/UpdateProject/${id}`, requestBody, user)
                            setLoader(false);
console.log(response);


                            if (response.statusCode === 201) {
                                navigate("/project")
                                resetForm()
                                setLoader(false);

                                const Erorr = {
                                    statusCode: 0,
                                    message: '',
                                };
                                setEror(Erorr)
                            }
                        }

                    }
                    else {
                        const requestBody = {

                            projectYear: new Date(values.date).toISOString(),
                            photoPath: photo,
                            language: [
                                {

                                    title: values.az.title,
                                    description: values.az.description,
                                    langCode: "az",
                                    metaKey: values.az.metaKey,
                                    metaDescription: values.az.metaDescription,
                                    location: values.az.location,
                                    service: values.az.service,
                                },
                                {
                                    title: values.ru.title,
                                    description: values.ru.description,
                                    langCode: "ru-RU",
                                    metaKey: values.ru.metaKey,
                                    metaDescription: values.ru.metaDescription,
                                    location: values.ru.service,
                                    service: values.ru.service,
                                },
                                {
                                    title: values.en.title,
                                    description: values.en.description,
                                    langCode: "en-US",
                                    metaKey: values.en.metaKey,
                                    metaDescription: values.en.metaDescription,
                                    location: values.en.location,
                                    service: values.en.service,
                                }
                            ],
                            photoPaths: imageUrl.message
                        }

                        const response = await putServices(`/Project/UpdateProject/${id}`, requestBody, user)
                        setLoader(false);


                        if (response.statusCode === 201) {
                            navigate("/project")
                            resetForm()
                            setLoader(false);

                            const Erorr = {
                                statusCode: 0,
                                message: '',
                            };
                            setEror(Erorr)
                        }
                        setLoader(false);


                    }
                } 
             

            }
            else {
                if (values.photo) {
                    const imageUr =await upload(values.photo,user,navigate);
                    
                    setEror(imageUr)
                    if (imageUr.statusCode === 200) {
                        const requestBody = {

                            projectYear: new Date(values.date).toISOString(),
                            photoPath: imageUr.message,
                            language: [
                                {

                                    title: values.az.title,
                                    description: values.az.description,
                                    langCode: "az",
                                    metaKey: values.az.metaKey,
                                    metaDescription: values.az.metaDescription,
                                    location: values.az.location,
                                    service: values.az.service,
                                },
                                {
                                    title: values.ru.title,
                                    description: values.ru.description,
                                    langCode: "ru-RU",
                                    metaKey: values.ru.metaKey,
                                    metaDescription: values.ru.metaDescription,
                                    location: values.ru.service,
                                    service: values.ru.service,
                                },
                                {
                                    title: values.en.title,
                                    description: values.en.description,
                                    langCode: "en-US",
                                    metaKey: values.en.metaKey,
                                    metaDescription: values.en.metaDescription,
                                    location: values.en.location,
                                    service: values.en.service,
                                }
                            ],
                            photoPaths: files.map((x: any) => ({
                                fileName: x.fileName,
                                pathOrContainerName: x.path
                            })),
                        }
                        const response = await putServices(`/Project/UpdateProject/${id}`, requestBody, user)
                        setLoader(false);


                        if (response.statusCode === 201) {
                            navigate("/project")
                            resetForm()
                            setLoader(false);

                            const Erorr = {
                                statusCode: 0,
                                message: '',
                            };
                            setEror(Erorr)
                        }
                    }

                }
                else {
                    const requestBody = {

                        projectYear: new Date(values.date).toISOString(),
                        photoPath: photo,
                        language: [
                            {

                                title: values.az.title,
                                description: values.az.description,
                                langCode: "az",
                                metaKey: values.az.metaKey,
                                metaDescription: values.az.metaDescription,
                                location: values.az.location,
                                service: values.az.service,
                            },
                            {
                                title: values.ru.title,
                                description: values.ru.description,
                                langCode: "ru-RU",
                                metaKey: values.ru.metaKey,
                                metaDescription: values.ru.metaDescription,
                                location: values.ru.service,
                                service: values.ru.service,
                            },
                            {
                                title: values.en.title,
                                description: values.en.description,
                                langCode: "en-US",
                                metaKey: values.en.metaKey,
                                metaDescription: values.en.metaDescription,
                                location: values.en.location,
                                service: values.en.service,
                            }
                        ],
                        photoPaths: files.map((x: any) => ({
                            fileName: x.fileName,
                            pathOrContainerName: x.path
                        })),
                    }

                    const response = await putServices(`/Project/UpdateProject/${id}`, requestBody, user)
                    setLoader(false);


                    if (response.statusCode === 201) {
                        navigate("/project")
                        resetForm()
                        setLoader(false);

                        const Erorr = {
                            statusCode: 0,
                            message: '',
                        };
                        setEror(Erorr)
                    }
                    setLoader(false);


                }

            }

        }
    });
    console.log(formik.values.photo);
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yeniləyin" pageTitle="Layihə" />
                    {eror?.statusCode === 400 && <Alert variant="danger">{eror.message}</Alert>}
                    <Row>
                        <Col lg={12}>
                            <Card >
                                <Card.Body>
                                    <Row >
                                        <Col xxl={4}>
                                        <Col xxl={12}>
                                <label htmlFor="photo" className="form-label">Əsas şəkli</label>
        <input
          id="photo"
          name="photo"
          accept="image/png,image/jpeg"
          type="file"
          className={`form-control ${formik.touched.photo && formik.errors.photo ? 'border border-danger' : ''}`}
          onChange={(event) => {
            formik.setFieldValue('photo', event.currentTarget.files ? event.currentTarget.files[0] : null);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.photo && formik.errors.photo ? (
          <div className="text-danger">{formik.errors.photo}</div>
        ) : null}
      
      {formik.values.photo ? (
           <div className="mt-3 d-flex justify-content-center">
           <div style={{height: '200px',width:"100%", overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
             <img  src={URL.createObjectURL(formik.values.photo)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
           </div>
         </div>
      ):(
        <div className="mt-3 d-flex justify-content-center">
        <div style={{height: '200px',width:"100%", overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
          <img  src={`${photo}`} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
        </div>
      </div>
      )}
      
                                </Col>
                                            <Col xxl={12}>
                                            <div className="mb-3">
                                                <label htmlFor="image" className="form-label">Layihə şəkli</label>

                                                <input
                                                    id="image"
                                                    type="file"
                                                    multiple
                                                    accept="image/png,image/jpeg"
                                                    className={`form-control ${formik.touched.photos && formik.errors.photos ? 'border border-danger' : ''}`}
                                                    onChange={(event) => {
                                                        const files = event.currentTarget.files;
                                                        if (files) {
                                                            const filesArray: FileWithPreview[] = Array.from(files).map((file) => ({
                                                                file,
                                                                preview: URL.createObjectURL(file),
                                                            }));
                                                            formik.setFieldValue('photos', filesArray);
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.photos && formik.errors.photos ? (
                                                    <div className="text-danger">{formik.errors.photos}</div>
                                                ) : null}
                                                <ul className="list-unstyled mb-0" id="dropzone-preview">
                                                    {formik.values.photos.length > 0 ?
                                                        Array.from(formik.values.photos).map((photo: any, index) => (
                                                            <li key={index} className="mt-2 dz-processing dz-image-preview dz-success dz-complete">
                                                                <div className="border rounded">
                                                                    <div className="d-flex p-2">
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div className="avatar-sm bg-light rounded">
                                                                                <img className="img-fluid rounded d-block" src={URL.createObjectURL(photo.file)} alt={URL.createObjectURL(photo.file)} />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )) : (
                                                            files.length > 0 &&
                                                            files.map((photo: any, index: any) => (
                                                                <li key={index} className="mt-2 dz-processing dz-image-preview dz-success dz-complete">
                                                                    <div className="border rounded">
                                                                        <div className="d-flex p-2">
                                                                            <div className="flex-shrink-0 me-3">
                                                                                <div className="avatar-sm bg-light rounded">
                                                                                    <img className="img-fluid rounded d-block" src={`${photo.path}`} alt={photo.fileName} />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )
                                                    }
                                                </ul>

                                            </div> 
                                            
                                            </Col>
                                            </Col>
                                        <Col xxl={8}>
                                            <Form action="#" onSubmit={(e) => {
                                                e.preventDefault(); formik.handleSubmit();
                                                return false;
                                            }}>
                                                <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
                                                    <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.az !== undefined && "text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.ru !== undefined && "text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.en !== undefined && "text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>

                                                    <Tab.Content className="tab-content" id="nav-tabContent">
                                                        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="az.title"
                                                                    name="az.title"
                                                                    placeholder="Layihənin adını daxil edin"
                                                                    value={formik.values.az.title}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.az?.title} />
                                                                {formik.errors.az?.title && formik.touched.az?.title ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.az.title}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="az.description"
                                                                    name="az.description"
                                                                    placeholder="Layihənin təsvirini daxil edin"
                                                                    value={formik.values.az.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.az?.description} />
                                                                {formik.errors.az?.description && formik.touched.az?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.az.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="az.metaDescription"
                                                                    name="az.metaDescription"
                                                                    placeholder="Layihənin meta təsvirini daxil edin"
                                                                    value={formik.values.az.metaDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.az?.metaDescription} />
                                                                {formik.errors.az?.metaDescription && formik.touched.az?.metaDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.az.metaDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="az.metaKey"
                                                                    name="az.metaKey"
                                                                    placeholder="Layihənin metaKeyini daxil edin"
                                                                    value={formik.values.az.metaKey}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.az?.metaKey} />
                                                                {formik.errors.az?.metaKey && formik.touched.az?.metaKey ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.az.metaKey}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="az.location"
                                                                            name="az.location"
                                                                            placeholder="Layihənin yerini daxil edin"
                                                                            value={formik.values.az.location}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.az?.location} />
                                                                        {formik.errors.az?.location && formik.touched.az?.location ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.az.location}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="az.service"
                                                                            name="az.service"
                                                                            placeholder="Layihənin xidmətini daxil edin"
                                                                            value={formik.values.az.service}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.az?.service} />
                                                                        {formik.errors.az?.service && formik.touched.az?.service ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.az.service}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.title"
                                                                    name="ru.title"
                                                                    placeholder="Layihənin adını daxil edin"
                                                                    value={formik.values.ru.title}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.title} />
                                                                {formik.errors.ru?.title && formik.touched.ru?.title ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.title}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.description"
                                                                    name="ru.description"
                                                                    placeholder="Layihənin təsvirini daxil edin"
                                                                    value={formik.values.ru.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.description} />
                                                                {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.metaDescription"
                                                                    name="ru.metaDescription"
                                                                    placeholder="Layihənin meta təsvirini daxil edin"
                                                                    value={formik.values.ru.metaDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.metaDescription} />
                                                                {formik.errors.ru?.metaDescription && formik.touched.ru?.metaDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru.metaDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.metaKey"
                                                                    name="ru.metaKey"
                                                                    placeholder="Layihənin MetaKeyini daxil edin"
                                                                    value={formik.values.ru.metaKey}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.metaKey} />
                                                                {formik.errors.ru?.metaKey && formik.touched.ru?.metaKey ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.metaKey}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="ru.location"
                                                                            name="ru.location"
                                                                            placeholder="Layihənin yerini daxil edin"
                                                                            value={formik.values.ru.location}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.ru?.location} />
                                                                        {formik.errors.ru?.location && formik.touched.ru?.location ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.ru.location}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="ru.service"
                                                                            name="ru.service"
                                                                            placeholder="Layihənin xidmətini daxil edin"
                                                                            value={formik.values.ru.service}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.ru?.service} />
                                                                        {formik.errors.ru?.service && formik.touched.ru?.service ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.ru.service}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                            </Row>


                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.title"
                                                                    name="en.title"
                                                                    placeholder="Layihənin adını daxil edin"
                                                                    value={formik.values.en.title}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.title} />
                                                                {formik.errors.en?.title && formik.touched.en?.title ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.title}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.description"
                                                                    name="en.description"
                                                                    placeholder="Layihənin təsvirini daxil edin"
                                                                    value={formik.values.en.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.description} />
                                                                {formik.errors.en?.description && formik.touched.en?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.metaDescription"
                                                                    name="en.metaDescription"
                                                                    placeholder="Layihənin Meta təsvirini daxil edin"
                                                                    value={formik.values.en.metaDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.metaDescription} />
                                                                {formik.errors.en?.metaDescription && formik.touched.en?.metaDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.metaDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.metaKey"
                                                                    name="en.metaKey"
                                                                    placeholder="Layihənin MetaKeyini daxil edin"
                                                                    value={formik.values.en.metaKey}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.metaKey} />
                                                                {formik.errors.en?.metaKey && formik.touched.en?.metaKey ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.metaKey}</Form.Control.Feedback>
                                                                ) : null}

                                                            </div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="en.location"
                                                                            name="en.location"
                                                                            placeholder="Layihənin yerini daxil edin"
                                                                            value={formik.values.en.location}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.en?.location} />
                                                                        {formik.errors.en?.location && formik.touched.en?.location ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.en.location}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="en.service"
                                                                            name="en.service"
                                                                            placeholder="Layihənin xidmətini daxil edin"
                                                                            value={formik.values.en.service}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.en?.service} />
                                                                        {formik.errors.en?.service && formik.touched.az?.service ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.en.service}</Form.Control.Feedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>

                                                <div className="mb-3">
                                                    <label htmlFor="date" className="form-label">Tarix</label>
                                                    <input
                                                        id="date"
                                                        name="date"
                                                        type="date"
                                                        className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                        value={formik.values.date}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.date && formik.errors.date ? (
                                                        <div className="invalid-feedback">{formik.errors.date}</div>
                                                    ) : null}
                                                </div>
                                                <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                                    {loader && <Spinner size="sm" animation="border" />} {"  "}
                                                    <span className="btn-text">
                                                        Yadda saxla
                                                    </span>
                                                </Button>

                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment >
    );
}

