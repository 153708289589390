import { createContext, useContext, useEffect, useState } from "react";
import api from "../utils/api";
import { AuthContextType,  Erorr, UserLogin } from "../../types/auth";
import { useLocation, useNavigate } from "react-router-dom";
export const secretKey = 'webconsole-studio-secretKey';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth bir AuthProvider daxilində istifadə edilməlidir');
  }
  return context;
};




export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const user= localStorage.getItem('auth');
  const [error, setEror] = useState<Erorr | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);

  const Update = () => {
    setUpdate(!update)
  };
  const location = useLocation();


  useEffect(() => {

    const storedData = localStorage.getItem('refres');
    if (storedData) {

    } else {
      if (
        location.pathname !== '/auth-404' &&
        location.pathname !== '/auth-500' &&
        location.pathname !== '/auth-503' &&
        location.pathname !== '/auth-offline'
      ) {
        navigate("/login");
      }
    }
  }, [update, navigate, location.pathname]);


  const login = (userLogin: UserLogin, history: any) => {
    setLoading(true)
    api.post('/Auth/Login', {
      emailOrUsername: userLogin.emailOrUsername,
      password: userLogin.password,
    })
      .then(response => {
        setLoading(false);
        if (response.status === 200) {

          const Erorr = {
            statusCode: response.data.statusCode,
            message: response.data.message || '',
          };
          setEror(Erorr);
          localStorage.setItem('refres', response.data.data.refreshToken)
          localStorage.setItem('auth', response.data.data.accessToken);
          history('/');
          setEror(null);

        }



      })
      .catch(error => {
        setLoading(false);
        if (error.code === "ERR_NETWORK") {
          navigate("/auth-offline");
        }






        if (error && error.response) {

          const Erorr = {
            statusCode: error.response.status,
            message: error.response.data.message || '',
          };

          setEror(Erorr);

        }
      });
  };

  const refreshToken = async (token:string) => {

    return new Promise((resolve, reject) => {
      api.post(`/Auth/RefreshTokenLogin`, {
        refreshToken: token
      },{  headers: {
        'Content-Type': 'application/json', 
        }})
        .then(response => {
          localStorage.setItem('auth', response.data.data.accessToken);
          const user = response.data.data.accessToken;
          Update()
          resolve(user);
        })
        .catch(error => {
            reject(error);
        });
    });
  };

  api.interceptors.response.use(
    response => response,

    async (error) => {
      
      const tokens = localStorage.getItem("refres")

        if (error.response && error.response.status === 401) {         
          try {
            if (tokens) {
            const token = await refreshToken(localStorage.getItem("refres") as string);
            localStorage.setItem('auth', token as string);
            const originalRequest = error.response.config;
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          }

          } catch (refreshError:any) {
            console.log(refreshError);
            
            if (refreshError.response.status===500||refreshError.response.status===401) {
              localStorage.removeItem('auth');
            localStorage.removeItem('refres');
            navigate('/login'); 
          }
            return Promise.reject(refreshError);
          }}
        
     
      

      return Promise.reject(error);
    }
  );
  const logout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('refres');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};