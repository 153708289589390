import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";

import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import { putServices } from "helpers/put";
import { GetAbotUpdate } from "interface/about";
import { useAuth } from "context/AuthContext";
export const AboutUpdate = () => {
    
    const { id } = useParams<{ id: string }>();
    const [loader, setLoader] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        
        const fetchData = async () => {
          const data = await getServices<GetAbotUpdate>(`/About/GetAbout/${id}`,user);
     
          formik.setValues({
            az: {
                content : data?.language.find(x=>x.langCode==="az")?.content||"",
         },
            ru: {
                content: data?.language.find(x=>x.langCode==="ru-RU")?.content||"",
                       },
            en: {
                content: data?.language.find(x=>x.langCode==="en-US")?.content||"",
                    }
        });
        };
   
    
        fetchData();
      }, [t,id]);

    const formik = useFormik({
        initialValues: {
            az: {
                content: "",
            },
            ru: {
                content: "",
            },
            en: {
                content: "",
            }
        },
        validationSchema: Yup.object({
            az: Yup.object().shape({
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                         }),
            ru: Yup.object().shape({
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                         }),
            en: Yup.object().shape({
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                        })
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);

            const requestBody = {
                language: [
                  {
                    content: values.az.content,
                    langCode: "az",
                        },
                  {
                    content: values.ru.content,
                    langCode: "ru-RU",
                   
                  }, 
                  {
                    content: values.en.content,
                    langCode: "en-US",
                   
                  }
                ],
          
            }
          
           const response= await putServices(`/About/UpdateProject/${id}`,requestBody,user,navigate)
           setLoader(false);
       
        
          
          if (response.statusCode===201) {
              navigate("/about")
            resetForm()
          }
          
           
        }
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Yeniləyin" pageTitle="Haqqında" />
            <Row>
                <Col lg={12}>
                    <Card >
                        <Card.Body>
                            <Row >
                           
                                <Col xxl={12}>
                                    <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                    <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
      <Nav variant="tabs" className="nav nav-tabs" role="tablist">
        <Nav.Item>
          <Nav.Link className={`${formik.errors.az!==undefined&&"text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.ru!==undefined&&"text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.en!==undefined&&"text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
        </Nav.Item>
      </Nav>
     
      <Tab.Content className="tab-content" id="nav-tabContent">
        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
        <div className="my-3">
                                            <Form.Control
                                                    as="textarea"
                                                id="az.content"
                                                name="az.content"
                                                placeholder="Mətnin məzmununu daxil edin"
                                                value={formik.values.az.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.content} />
                                            {formik.errors.az?.content && formik.touched.az?.content ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.content}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                  
                                       
                     
        </Tab.Pane>
     
        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
        <div className="my-3">
                                            <Form.Control
                                                   as="textarea"
                                                id="ru.content"
                                                name="ru.content"
                                                placeholder="Mətnin məzmununu daxil edin"
                                                value={formik.values.ru.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.content} />
                                            {formik.errors.ru?.content && formik.touched.ru?.content ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.content}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
               
        </Tab.Pane>
        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
        <div className="my-3">
                                            <Form.Control
                                                  as="textarea"
                                                id="en.content"
                                                name="en.content"
                                                placeholder="Mətnin məzmununu daxil edin"
                                                value={formik.values.en.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.content} />
                                            {formik.errors.en?.content && formik.touched.en?.content ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.content}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                  
                                       
                     
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
                                     
                                     
            <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                                Yadda saxla
                                            </span>
                                        </Button>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>

            </div>
        </React.Fragment >
    );
}

