import BreadCrumb from 'Common/BreadCrumb'
import React from 'react'
import { Button, Card, Col, Container } from 'react-bootstrap'
import StandardImageList from './PhotoList'
import { Link } from 'react-router-dom'


export default function PhotoGallery() {
  return (
    <React.Fragment>
    <div className="page-content">
        <Container fluid>
            <BreadCrumb title="Qalereya" pageTitle="Admin" />
            <Card>
            <Card.Header>
            <Link to={"/photoGallery/create"}> <Button className="btn-primary   bg-gradient">Yarat</Button></Link>
            </Card.Header>
            <Card.Body>
            <StandardImageList/>
            </Card.Body>
          </Card>
            <Col lg={12}>
            
   
                
                </Col>
                
                </Container>
            </div>
            </React.Fragment>
  )
}
