import BreadCrumb from 'Common/BreadCrumb';
import React from 'react';
import { Container } from 'react-bootstrap';
// Import Components


const Ecommerce = () => {
    document.title = "Admin | Texno Global ";

    
    return (
        <React.Fragment>
            <div className ="page-content">
                <Container fluid>
                    <BreadCrumb title="Admin" pageTitle="Texno Global" />
                </Container>
    
            </div>
        </React.Fragment>
    );
};

export default Ecommerce;
