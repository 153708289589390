import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationRS from "Common/locales/ru.json";
import translationENG from "Common/locales/en.json";
import translationAZ from "Common/locales/az.json";

// the translations
const resources = {
  az: {
    translation: translationAZ,
  },
  en: {
    translation: translationENG,
  },
  ru: {
    translation: translationRS,
  }

 
 
};

const language =  localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "az");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "az",
    fallbackLng: "az", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
