import { AxiosResponse } from "axios";
import api from "utils/api";
import { Erorr } from "../../types/auth";

export async function patchServices(url: string,token?:string|null,navigate?:any) {
    try {
      const response: AxiosResponse = await api.patch(url,null, {
        headers: {
        'Content-Type': 'application/json', 
          "Authorization": `Bearer ${token}`
        }
      });
  
      if (!response.data.success) {
        throw new Error('Məlumatların axtarışı uğurlu alınmadı.');
      }
      const Erorr = {
        statusCode: response.status,
        message: response.data.message || '',
      };

  return Erorr as Erorr;
  
    } catch (error :any) {
      if (error.code==="ERR_NETWORK") {
        navigate("/auth-offline");
      }
    
        
            const Erorr = {
            statusCode: error.response.status||0,
            message: error.response.data.message || '',
          };
         
      return Erorr as Erorr;
    }
  }