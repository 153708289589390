import BreadCrumb from 'Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { getServices } from 'helpers/get';
import { useTranslation } from 'react-i18next';
import { Link} from 'react-router-dom';
// import { deleteServices } from 'helpers/delete';

// import Swal from 'sweetalert2';
import { GetArticleCategory } from 'interface/category';
import { useAuth } from 'context/AuthContext';
// Import Components


const About = () => {
    document.title = "Admin | Texno Global";
const [articles, setArticles] = useState<any>([]);
const { t } = useTranslation();
const {user} = useAuth();
// const [isDeleite, setIsDeleite] = useState<boolean>(false);
// const Deleite = async (id: string)  => {
//   const result = await Swal.fire({
//     title: 'Are you sure?',
//     text: "You won't be able to revert this!",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, delete it!'
//   });

//   if (result.isConfirmed) {
//   const response = await  deleteServices(`/Categoey/${id}`,user)
 
//   if (response.statusCode===200) {
//     IsDeleite();
//     Swal.fire({
//       title: 'Deleted!',
//       text:`${response.message}`,
//       icon: 'success'
//     });
//   }
//   }}
  // const IsDeleite =  () => {
  //   setIsDeleite(!isDeleite)
  // }


    useEffect(() => {
        const fetchData = async () => {
          const data = await getServices<GetArticleCategory[]>("/About/get-last-about",user, t('leng'));
          setArticles(data);
        };
    
        fetchData();
      }, [t]);
       
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Haqqında" pageTitle="Admin" />
                    <Col lg={12}>
          <Card>
            <Card.Header>
            <Link to={`/about/update/${articles&&articles.id}`}> <Button className="btn-primary   bg-gradient">Yeniləyin</Button></Link>
            </Card.Header>
            <Card.Body>
            {/* <Tabledata Deleite={Deleite} data={articles} /> */}
            <p>{articles&&articles.content}</p>
            </Card.Body>
          </Card>
        </Col>
                    
                   
                </Container>
            </div>
        </React.Fragment>
    );
};

export default About;
