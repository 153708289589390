import React, {useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { postServices } from "helpers/post";

import { useNavigate } from "react-router-dom";
import { Erorr } from "../../../../types/auth";
import upload from "helpers/uplouds";
import { FileWithPreview } from "../Article/create";
import { useAuth } from "context/AuthContext";
export const FeedbackCreate = () => {
    
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const {user} = useAuth();
    const [eror, setEror] = useState<Erorr>();
    const formik = useFormik({
        initialValues: {
            image: null,
            az: {
                name: "",
                location: "",
                content: "",
           
            },
            ru: {
                name: "",
                location: "",
                content: "",
            },
            en: {
                name: "",
                location: "",
                content: "",
            }
        },
        validationSchema: Yup.object({
            image: Yup.mixed<FileWithPreview>()
            .required('Şəkil seçilməlidir'),
            az: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                location: Yup.string().required("Zəhmət olmasa məkanı daxil edin"),
               
            }),
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                location: Yup.string().required("Zəhmət olmasa məkanı daxil edin"),

              }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
                content: Yup.string().required("Zəhmət olmasa məzmununu daxil edin"),
                location: Yup.string().required("Zəhmət olmasa məkanı daxil edin"),

             })
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);
           const imageUrl = await upload(values.image,user,navigate);
           setEror(imageUrl)
           if (imageUrl.statusCode===200) {
            const requestBody = {
                photoPath: imageUrl.message,
                addFeedbackLanguageDTOs: [
                  {
                    name: values.az.name,
                    content: values.az.content,
                    langCode: "az",
                    location: values.az.location,
                  
                  },
                  {
                    name: values.ru.name,
                    content: values.ru.content,
                    langCode: "ru-RU",
                    location: values.ru.location,
                  }, 
                  {
                    name: values.en.name,
                    content: values.en.content,
                    langCode: "en-US",
                    location: values.en.location,
                  }
                ],
            }
          
           const response= await postServices("/Feedback/Create",requestBody,user,navigate)
           setLoader(false);
          
          if (response.statusCode===201) {
              navigate("/feedback")
            resetForm()
            const Erorr = {
                statusCode: 0,
                message: '',
              };
            setEror(Erorr)
          }
        }
        setLoader(false);
    }
        
    });
  
 

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Yarat" pageTitle="Rəy" />
            <Row>
            {eror?.statusCode===400&&<Alert variant="danger">{eror.message}</Alert>}
                <Col lg={12}>
                    <Card >
                        <Card.Body>
                            <Row >
                            <Col xxl={4}>
                            <label htmlFor="image" className="form-label">Rəy şəkli</label>
        <input
          id="image"
          name="image"
          accept="image/png,image/jpeg"
          type="file"
          className={`form-control ${formik.touched.image && formik.errors.image ? 'border border-danger' : ''}`}
          onChange={(event) => {
            formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : null);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-danger">{formik.errors.image}</div>
        ) : null}
          {formik.values.image && (
           <div className="mt-3 d-flex justify-content-center">
           <div style={{width:"100%", height: '200px', overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
             <img  src={URL.createObjectURL(formik.values.image)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
           </div>
         </div>
      )}
        </Col>
                                <Col xxl={8}>
                                    <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                    <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
      <Nav variant="tabs" className="nav nav-tabs" role="tablist">
        <Nav.Item>
          <Nav.Link className={`${formik.errors.az!==undefined&&"text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.ru!==undefined&&"text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.en!==undefined&&"text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
        </Nav.Item>
      </Nav>
     
      <Tab.Content className="tab-content" id="nav-tabContent">
        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.name"
                                                name="az.name"
                                                placeholder="Rəyin başlığını daxil edin"
                                                value={formik.values.az.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.name} />
                                            {formik.errors.az?.name && formik.touched.az?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.content"
                                                name="az.content"
                                                placeholder="Rəyin məzmununu daxil edin"
                                                value={formik.values.az.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.content} />
                                            {formik.errors.az?.content && formik.touched.az?.content ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.content} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.location"
                                                name="az.location"
                                                placeholder="Məkanı daxil edin"
                                                value={formik.values.az.location}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.location} />
                                            {formik.errors.az?.location && formik.touched.az?.location ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.location}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                       
                     
        </Tab.Pane>
     
        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.name"
                                                name="ru.name"
                                                placeholder="Rəyin başlığını daxil edin"
                                                value={formik.values.ru.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.name} />
                                            {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.content"
                                                name="ru.content"
                                                placeholder="Rəyin məzmununu daxil edin"
                                                value={formik.values.ru.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.content} />
                                            {formik.errors.ru?.content && formik.touched.ru?.content ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.content} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.location"
                                                name="ru.location"
                                                placeholder="Məkanı daxil edin"
                                                value={formik.values.ru.location}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.location} />
                                            {formik.errors.ru?.location && formik.touched.ru?.location ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.location}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                       
                     
        </Tab.Pane>
        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.name"
                                                name="en.name"
                                                placeholder="Rəyin başlığını daxil edin"
                                                value={formik.values.en.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.name} />
                                            {formik.errors.en?.name && formik.touched.en?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.content"
                                                name="en.content"
                                                placeholder="Rəyin məzmununu daxil edin"
                                                value={formik.values.en.content}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.content} />
                                            {formik.errors.en?.content && formik.touched.az?.content ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.content} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        
                                        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.location"
                                                name="en.location"
                                                placeholder="Məkanı daxil edin"
                                                value={formik.values.en.location}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.location} />
                                            {formik.errors.en?.location && formik.touched.en?.location ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.location}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                       
                     
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
                                     
                                       
            <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                                Yadda saxla
                                            </span>
                                        </Button>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>

            </div>
        </React.Fragment >
    );
}

