import flagus from "assets/images/flags/us.svg";
import flagaz from "assets/images/flags/az.svg";
import flagrussia from "assets/images/flags/russia.svg";
const languages = {
  az: {
    label: "Azerbaycan",
    flag: flagaz,
  },
  en: {
    label: "English",
    flag: flagus,
  },
  ru: {
    label: "Pусский",
    flag: flagrussia,
  },
 
}

export default languages
