import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { postServices } from "helpers/post";

import { useNavigate } from "react-router-dom";
import { GetArticleCategory } from "interface/category";
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import upload from "helpers/uplouds";
import { Erorr } from "../../../../types/auth";
import { useAuth } from "context/AuthContext";
export interface FileWithPreview extends File {
    preview?: string;
  }
export const ArticleCreate = () => {
    const {user} = useAuth();
    const [loader, setLoader] = useState<boolean>(false);
    const [categorydata, setCategorydata] = useState<any>([]);
    const [eror, setEror] = useState<Erorr>();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            categoryId: '',
            image: null,
            az: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                metaTags: ""
            },
            ru: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                metaTags: ""
            },
            en: {
                title: "",
                description: "",
                metaKey: "",
                metaDescription: "",
                metaTags: ""
            }
        },
        
        validationSchema: Yup.object({
            categoryId: Yup.string().required("Zəhmət olmasa kateqoriyanı daxil edin"),
            image: Yup.mixed<FileWithPreview>()
            .required('Şəkil seçilməlidir'),
            az: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                metaTags: Yup.string().required("Zəhmət olmasa MetaTəqləri daxil edin")
            }),
            ru: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                metaTags: Yup.string().required("Zəhmət olmasa MetaTəqləri daxil edin")
              }),
            en: Yup.object().shape({
                title: Yup.string().required("Zəhmət olmasa başlığı daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa MetaKeyi daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa MetaTəsviri daxil edin"),
                metaTags: Yup.string().required("Zəhmət olmasa MetaTəqləri daxil edin")
             })
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);
     
           
           const imageUrl = await upload(values.image,user,navigate);
           setEror(imageUrl)
        
           
           if (imageUrl.statusCode===200) {
            const requestBody = {
                photoPath: imageUrl.message,
                articleLanguages: [
                  {
                    title: values.az.title,
                    description: values.az.description,
                    langCode: "az",
                    metaKey: values.az.metaKey,
                    metaDescription: values.az.metaDescription,
                    metaTags: values.az.metaTags
                  },
                  {
                    title: values.ru.title,
                    description: values.ru.description,
                    langCode: "ru-RU",
                    metaKey: values.ru.metaKey,
                    metaDescription: values.ru.metaDescription,
                    metaTags: values.ru.metaTags
                  }, 
                  {
                    title: values.en.title,
                    description: values.en.description,
                    langCode: "en-US",
                    metaKey: values.en.metaKey,
                    metaDescription: values.en.metaDescription,
                    metaTags: values.en.metaTags
                  }
                ],
                categoryId: values.categoryId
            }
          
           const response= await postServices("/Article",requestBody,user,navigate)
           setLoader(false);
          
          if (response.statusCode===201) {
              navigate("/article")
            resetForm()
            const Erorr = {
                statusCode: 0,
                message: '',
              };
            setEror(Erorr)
          }
           }
           setLoader(false);
           
          
           
        }
    });
    useEffect(() => {
        const fetchData = async () => {
          const data = await getServices<GetArticleCategory[]>("/Category",user, t('leng'));
        setCategorydata(data);
        };
    
        fetchData();
      }, [t]);


  



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Yarat" pageTitle="Bloq" />
            <Row>
                {eror?.statusCode===400&&<Alert variant="danger">{eror.message}</Alert>}
            
                <Col lg={12}>
                    <Card >
                        <Card.Body>
                            <Row >
                            <Col xxl={4}>
                            <label htmlFor="image" className="form-label">Bloq şəkli</label>
        <input
          id="image"
          name="image"
          accept="image/png,image/jpeg"
          type="file"
          className={`form-control ${formik.touched.image && formik.errors.image ? 'border border-danger' : ''}`}
          onChange={(event) => {
            formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : null);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-danger">{formik.errors.image}</div>
        ) : null}
          {formik.values.image && (
           <div className="mt-3 d-flex justify-content-center">
           <div style={{height: '200px',width:"100%", overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
             <img src={URL.createObjectURL(formik.values.image)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
           </div>
         </div>
      )}
      
        </Col>
                                <Col xxl={8}>
                                    <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                    <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
      <Nav variant="tabs" className="nav nav-tabs" role="tablist">
        <Nav.Item>
          <Nav.Link className={`${formik.errors.az!==undefined&&"text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.ru!==undefined&&"text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.en!==undefined&&"text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
        </Nav.Item>
      </Nav>
     
      <Tab.Content className="tab-content" id="nav-tabContent">
        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.title"
                                                name="az.title"
                                                placeholder="Bloqun başlığını daxil edin"
                                                value={formik.values.az.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.title} />
                                            {formik.errors.az?.title && formik.touched.az?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.description"
                                                name="az.description"
                                                placeholder="Bloqun təsvirini daxil edin"
                                                value={formik.values.az.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.description} />
                                            {formik.errors.az?.description && formik.touched.az?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="az.metaDescription"
                                                name="az.metaDescription"
                                                placeholder="Bloqun meta təsvirini daxil edin"
                                                value={formik.values.az.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.metaDescription} />
                                            {formik.errors.az?.metaDescription && formik.touched.az?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.az.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <Row>
                                            <Col lg={6}>
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.metaKey"
                                                name="az.metaKey"
                                                placeholder="Bloqun metaKeyini daxil edin"
                                                value={formik.values.az.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.metaKey} />
                                            {formik.errors.az?.metaKey && formik.touched.az?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                <Col lg={6}>
                                                <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.metaTags"
                                                name="az.metaTags"
                                                placeholder="Bloqun metaTəqlərini daxil edin"
                                                value={formik.values.az.metaTags}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.metaTags} />
                                            {formik.errors.az?.metaTags && formik.touched.az?.metaTags ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.metaTags}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                </Row>
                                       
                     
        </Tab.Pane>
     
        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.title"
                                                name="ru.title"
                                                placeholder="Bloqun başlığını daxil edin"
                                                value={formik.values.ru.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.title} />
                                            {formik.errors.ru?.title && formik.touched.ru?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.description"
                                                name="ru.description"
                                                placeholder="Bloqun təsvirini daxil edin"
                                                value={formik.values.ru.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.description} />
                                            {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="ru.metaDescription"
                                                name="ru.metaDescription"
                                                placeholder="Bloqun meta təsvirini daxil edin"
                                                value={formik.values.ru.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.metaDescription} />
                                            {formik.errors.ru?.metaDescription && formik.touched.ru?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <Row>
                                            <Col lg={6}>
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.metaKey"
                                                name="ru.metaKey"
                                                placeholder="Bloqun metaKeyini daxil edin"
                                                value={formik.values.ru.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.metaKey} />
                                            {formik.errors.ru?.metaKey && formik.touched.ru?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                <Col lg={6}>
                                                <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.metaTags"
                                                name="ru.metaTags"
                                                placeholder="Bloqun metaTəqlərini daxil edin"
                                                value={formik.values.ru.metaTags}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.metaTags} />
                                            {formik.errors.ru?.metaTags && formik.touched.ru?.metaTags ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.metaTags}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                </Row>
                                       
                     
        </Tab.Pane>
        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.title"
                                                name="en.title"
                                                placeholder="Bloqun başlığını daxil edin"
                                                value={formik.values.en.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.title} />
                                            {formik.errors.en?.title && formik.touched.en?.title ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.title}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                      <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.description"
                                                name="en.description"
                                                placeholder="Bloqun təsvirini daxil edin"
                                                value={formik.values.en.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.description} />
                                            {formik.errors.en?.description && formik.touched.en?.description ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="en.metaDescription"
                                                name="en.metaDescription"
                                                placeholder="Bloqun meta təsvirini daxil edin"
                                                value={formik.values.en.metaDescription}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.metaDescription} />
                                            {formik.errors.en?.metaDescription && formik.touched.en?.metaDescription ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.metaDescription} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                        <Row>
                                            <Col lg={6}>
                                            <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.metaKey"
                                                name="en.metaKey"
                                                placeholder="Bloqun metaKeyini daxil edin"
                                                value={formik.values.en.metaKey}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.metaKey} />
                                            {formik.errors.en?.metaKey && formik.touched.en?.metaKey ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.metaKey}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                <Col lg={6}>
                                                <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.metaTags"
                                                name="en.metaTags"
                                                placeholder="Bloqun metaTəqlərini daxil edin"
                                                value={formik.values.en.metaTags}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.metaTags} />
                                            {formik.errors.en?.metaTags && formik.touched.az?.metaTags ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.metaTags}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                                                </Col>
                                                </Row>
                                       
                     
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
                                     
                                        <div className="mb-3">
                                            <Form.Select
                                                className="form-control"
                                                name="categoryId"
                                                id="categoryId"
                                                value={formik.values.categoryId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.categoryId}
                                            >
                                                <option defaultValue="">Kateqoriyalar seçin</option>
                                                {categorydata && categorydata.map((x:GetArticleCategory, index:any) => {
                  return (
                    <option key={index} value={x.id}>{x.name}</option>

                  )})}
                                               
                                            </Form.Select>
                                            {formik.errors.categoryId && formik.touched.categoryId ? (
                                                <Form.Control.Feedback type="invalid"> {formik.errors.categoryId} </Form.Control.Feedback>
                                            ) : null}
                                        </div>
                                  
            <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                               Yadda saxla
                                            </span>
                                        </Button>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>

            </div>
        </React.Fragment >
    );
}

