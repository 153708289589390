import React from "react";

const Navdata = () => {
 

    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "article",
            label: "Bloq",
            icon: "ph-article",
            link: "/article", 
        },
        {
            id: "category",
            label: "Kateqoriya",
            icon: "ph-article",
            link: "/category", 
        },
        // {
        //     id: "feedback",
        //     label: "Rəy",
        //     icon: "ph-article",
        //     link: "/feedback", 
        // },
        {
            id: "project",
            label: "Layihə",
            icon: "ph-article",
            link: "/project", 
        },
        {
            id: "service",
            label: "Xidmət",
            icon: "ph-article",
            link: "/service", 
        },
        {
            id: "about",
            label: "Haqqında",
            icon: "ph-article",
            link: "/about", 
        },
        {
            id: "partner",
            label: "Tərəfdaş",
            icon: "ph-article",
            link: "/partner", 
        },
        {
            id: "photoGallery",
            label: "Qalereya",
            icon: "ph-article",
            link: "/photoGallery", 
        },
        {
            id: "register",
            label: "Qeydiyyat",
            icon: "ph-article",
            link: "/register", 
        }  , {
            id: "user",
            label: "İstifadəçi",
            icon: "ph-article",
            link: "/user", 
        },
        
        

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;