import BreadCrumb from 'Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { getServices } from 'helpers/get';
import { GetArticle } from 'interface/article';
import { Tabledata } from './DataTables/Tabledata';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { patchServices } from 'helpers/patch';
import { useAuth } from 'context/AuthContext';
// Import Components


const Project = () => {
    document.title = "Dashboard | Texno Global";
const [articles, setArticles] = useState<any>([]);
const { t } = useTranslation();
const {user} = useAuth();
const navigate = useNavigate();
const [isDeleite, setIsDeleite] = useState<boolean>(false);
const Deleite = async (id: string)  => {
  const result = await Swal.fire({
    title: 'Əminsiniz?',
    text: "Bunu geri qaytara bilməyəcəksiniz!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Bəli, silin!',
    cancelButtonText: 'Bağla'
  });

  if (result.isConfirmed) {
  const response = await  patchServices(`/Project/SoftDelete/${id}`,user,navigate)
 
  if (response.statusCode===200) {
    IsDeleite();
    Swal.fire({
      title: 'Silindi!',
      text:`Layihə uğurla silindi`,
      icon: 'success'
    });
  }
  }}
  const IsDeleite =  () => {
    setIsDeleite(!isDeleite)
  }


    useEffect(() => {
        const fetchData = async () => {
          const data = await getServices<GetArticle[]>("/Project/get-all-langCode",user, t('leng'));
          setArticles(data);
        };
    
        fetchData();
      }, [t,isDeleite]);
       
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Layihə" pageTitle="Admin" />
                    <Col lg={12}>
          <Card>
            <Card.Header>
            <Link to={"/project/create"}> <Button className="btn-primary   bg-gradient">Yarat</Button></Link>
            </Card.Header>
            <Card.Body>
            <Tabledata Deleite={Deleite} data={articles} />
            </Card.Body>
          </Card>
        </Col>
                    
                   
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Project;
