import BreadCrumb from 'Common/BreadCrumb'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import {Alert, Button, Col, Container, Spinner,Form } from 'react-bootstrap'
import * as Yup from "yup";
import { Erorr } from '../../../../types/auth';
import { FileWithPreview } from '../Article/create';
import {  useNavigate } from 'react-router-dom';
import upload from 'helpers/uplouds';
import { postServices } from 'helpers/post';
import { useAuth } from 'context/AuthContext';
export default function PartnerCreate() {
    const [loader, setLoader] = useState<boolean>(false);
    const [eror, setEror] = useState<Erorr>();
    const {user} = useAuth();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            image: null,
          
        },
        
        validationSchema: Yup.object({
            image: Yup.mixed<FileWithPreview>()
            .required('Şəkil seçilməlidir'),
           
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);
     
           
           const imageUrl = await upload(values.image,user,navigate);
           setEror(imageUrl)
        
           
           if (imageUrl.statusCode===200) {
            const requestBody = {
                fileName: imageUrl.message,
                pathOrContainerName:imageUrl.message
            }
          
           const response= await postServices("/Partner/CreatePartner",requestBody,user,navigate)
           setLoader(false);
          
          if (response.statusCode===200) {
              navigate("/partner")
            resetForm()
            const Erorr = {
                statusCode: 0,
                message: '',
              };
            setEror(Erorr)
          }
           }
           setLoader(false);
           
          
           
        }
    });
  return (
    <React.Fragment>
    <div className="page-content">
        <Container fluid>
            <BreadCrumb title="Tərəfdaş" pageTitle="Admin" />
            {eror?.statusCode===400&&<Alert variant="danger">{eror.message}</Alert>}
            <Col lg={12}>
            <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                         <Col xxl={4}>
        <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                                Yadda saxla
                                            </span>
                                        </Button>
                                        </Col>
            <Col xxl={4}>
                       
            <label htmlFor="image" className="form-label mt-2">Tərəfdaş şəkli</label>
        <input
          id="image"
          name="image"
          accept="image/png,image/jpeg"
          type="file"
          className={`form-control mt-2  ${formik.touched.image && formik.errors.image ? 'border border-danger' : ''}`}
          onChange={(event) => {
            formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : null);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-danger">{formik.errors.image}</div>
        ) : null}
          {formik.values.image && (
           <div className="mt-3 d-flex justify-content-center">
           <div style={{height: '200px',width:"100%", overflow: 'hidden', border: '1px solid #ccc', borderRadius: '5px' }}>
             <img src={URL.createObjectURL(formik.values.image)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}  />
           </div>
         </div>
      )}
        </Col>
   
        </Form>

            </Col>
                
                </Container>
            </div>
            </React.Fragment>
  )
}
