import api from "utils/api";

const multipluplouds = async (files: any | null, token: string | null,navigate?:any) => {
  if (!files || files.length === 0) {
    return { statusCode: 400, message: "Heç bir fayl seçilməyib." };
  }

  try {
    const formData = new FormData();
    Array.from(files).forEach((file: any) => {
      formData.append('formFiles', file.file, file.file.name); // FormData'ya dosyaları ekle
    });

    const response = await api.post('/Upload/UploadFiles?pathOrContainer=solution-s3', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = response.data;
console.log(responseData);


    return { statusCode: response.status, message: responseData };
  } catch (error :any) {
    if (error.code==="ERR_NETWORK") {
      navigate("/auth-offline");
    }
   

    const statusCode = error.response?.status || 500;
    const message = error.response?.data?.message || "Şəkil yükləmədə xəta baş verdi!";
    return { statusCode, message };
  }
};

export default multipluplouds;
