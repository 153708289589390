import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { postServices } from "helpers/post";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
export const CategoryCreate = () => {
    
    const [loader, setLoader] = useState<boolean>(false);
const {user} = useAuth();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
           
            az: {
                name: "",
              
            },
            ru: {
                name: "",
               
            },
            en: {
                name: "",
               
            }
        },
        validationSchema: Yup.object({
            az: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
               
            }),
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
              
              }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa adını daxil edin"),
               
             })
        }),
        onSubmit:async (values,{resetForm}) => {
           setLoader(true);

            const requestBody = {
                language: [
                  {
                    name: values.az.name,
                    langCode: "az",
                   
                  },
                  {
                    name: values.ru.name,
                    langCode: "ru-RU",
               
                  }, 
                  {
                    name: values.en.name,
                    langCode: "en-US",
                  
                  }
                ],
              
            }
          
           const response= await postServices("/Category",requestBody,user,navigate)
           setLoader(false);
       
        
          
          if (response.statusCode===201) {
              navigate("/category")
            resetForm()
          }
          
           
        }
    });
  
 

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Yarat" pageTitle="Kateqoriya" />
            <Row>
                <Col lg={12}>
                    <Card >
                        <Card.Body>
                            <Row >
                    
                                <Col xxl={12}>
                                    <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); formik.handleSubmit();
                                    return false;
                                }}>
                                    <Tab.Container id="nav-tab" defaultActiveKey="#nav-home">
      <Nav variant="tabs" className="nav nav-tabs" role="tablist">
        <Nav.Item>
          <Nav.Link className={`${formik.errors.az!==undefined&&"text-danger"}`} eventKey="#nav-home">Azərbaycan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.ru!==undefined&&"text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  className={`${formik.errors.en!==undefined&&"text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
        </Nav.Item>
      </Nav>
     
      <Tab.Content className="tab-content" id="nav-tabContent">
        <Tab.Pane eventKey="#nav-home" className="tab-pane fade show">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="az.name"
                                                name="az.name"
                                                placeholder="Kateqoriyanın adını daxil edin"
                                                value={formik.values.az.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.az?.name} />
                                            {formik.errors.az?.name && formik.touched.az?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.az.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
                 
                     
        </Tab.Pane>
     
        <Tab.Pane eventKey="#nav-profile" className="tab-pane  fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="ru.name"
                                                name="ru.name"
                                                placeholder="Kateqoriyanın adını daxil edin"
                                                value={formik.values.ru.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.ru?.name} />
                                            {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
            
                                       
                     
        </Tab.Pane>
        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
        <div className="my-3">
                                            <Form.Control
                                                type="text"
                                                id="en.name"
                                                name="en.name"
                                                placeholder="Kateqoriyanın adını daxil edin"
                                                value={formik.values.en.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.en?.name} />
                                            {formik.errors.en?.name && formik.touched.en?.name ? (
                                                <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                            ) : null}
                                    
                                        </div>
              
                                       
                     
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
                          
                                       
            <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                           <span className="btn-text">
                                                Yadda saxla
                                            </span>
                                        </Button>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container>

            </div>
        </React.Fragment >
    );
}

