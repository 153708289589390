import { GetArticle } from 'interface/article';
import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-data-table-component';



interface TabledataProps {
    data: GetArticle[]; // Veri prop'u
    Deleite : (id:string) => void;
  }
  
  const Tabledata: React.FC<TabledataProps> = ({Deleite, data }) => {
    let count=1;

    const columns = [
      
        {
            name: <span className='font-weight-bold fs-sm'>ID</span>,
            selector: (row: any) => count++,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Ad</span>,
            selector: (row: any) => row.name,
            sortable: true
        },
       
       
        {
            name: <span className='font-weight-bold fs-sm'>Məkan</span>,
            selector: (row: any) => row.location,
            sortable: true
        },
     
        
        {
            name: <span className='font-weight-bold fs-sm'>Fəaliyyət</span>,
            sortable: true,
            selector: (row: any) => (<div className='align-center  d-flex gap-3 '>
                 <h3 onClick={()=>Deleite(row.id)} className='pointer align-center  d-flex mt-2' >
                   
                        <i className="ri-delete-bin-fill align-center  text-danger  "></i>
                    </h3>
                     <h3 className='align-center  d-flex mt-2' >
                        <Link to={`/feedback/update/${row.id} `}>
                     <i className=" ri-pencil-fill align-center  text-success"></i>
                        </Link>
                 </h3>
                             
                </div>
                   
             
                )
       
        },
    ];

    return (
        <Table
            columns={columns}
            data={data}
            pagination
        />
    );
};


export { Tabledata };
