import api from "utils/api";
 const upload = async (file:any,token:string|null,navigate?:any)=> {
    try {
      const formData = new FormData();
      formData.append('formFile', file , file.name);
  
      const response = await api.post('/Upload/UploadFile?pathOrContainer=solution-s3', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
          "Authorization": `Bearer ${token}`
        }
      });
      const responseData = response.data.pathOrContainerName;
      const Erorr = {
        statusCode: response.status,
        message: responseData || "",
      };
      return Erorr;
  
    } catch (error:any) {
      if (error.code==="ERR_NETWORK") {
        navigate("/auth-offline");
      }
    
      const Erorr = {
        
        statusCode: error.response.status||0,
        message: "Şəkil yükləmədə xəta baş verdi!",
      };
      return Erorr;
    }
  };
  
  export default upload;
  